.footer{
    background-color:rgb(222, 236, 255);
    font-family: 'neue';
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    padding-left: 4%;
    padding-right: 4%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.logo2{
    font-family: 'harper';
    font-size: 40px;
}