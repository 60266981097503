.header{
    height: max-content;
    width: max-content;
}

.nav{
    z-index: 2;
    position: fixed;
    top: 0;
    width: 99.7%;
    height: 60px;
    display: flex;
    flex-direction: row;
    column-gap: 25%;
    background-color: rgb(238, 245, 255);
    border-bottom: 3px solid rgb(87, 132, 190);
    border-right: 3px solid rgb(87, 132, 190);
    border-left: 3px solid rgb(87, 132, 190);
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  
  .logo{
    padding-left: 5%;
    font-family: 'harper';
    font-size: 30px;
    display: flex;
    align-self: center;
    margin: 0;
  }
  
  .liste_nav{
    display: flex;
    align-self: center;
    column-gap: 35%;
    font-family: 'neue';
    color: black;
    font-size: 23px;
  }

  .liste_nav a{
    text-decoration: none;
    background-image: linear-gradient(rgb(119, 166, 228),#77a6e4);
    background-size: 0% 0.1em;
    background-position-y: 100%;
    background-position-x: 100%;
    background-repeat: no-repeat;
    transition: background-size 0.2s ease-in-out;
    color: black;
  }
  .liste_nav a:visited{
    color: black;
  }

  .liste_nav a:hover{
    color: rgb(119, 166, 228);
  }

  .liste_nav a:hover,
  .liste_nav a:focus,
  .liste_nav a:active {
  background-size: 100% 0.1em;
  background-position-x: 0%;
}

@media screen and (max-width: 431px){

  .nav{
    width: 100%;
    column-gap: 2px;
  }
  .logo{
    display: none;
  }

  .liste_nav{
    font-size: 18px;
    padding-left: 8%;
    column-gap: 20%;
    
  }
}