.about{
    margin-bottom: 5%;
}

.expli{
    font-family: 'neue';
    margin-top: 5%;
    font-size: 25px;
    word-spacing: 5px;
    display: flex;
    flex-direction: row;
    column-gap: 100px;
    justify-content: center;
}

.expli p{
    margin-top: 0;
    width: 700px;
}

.img_birm{
    width: 650px;
}

.stats{
    margin-top: 1%;
    display: flex;
    justify-content: center;
    column-gap: 150px;
}

.stat1{
    margin-top: 5%;
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgb(238, 245, 255);
    border: 4px solid rgb(87, 132, 190);
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    font-family: 'neue';
    border-radius: 15px;
    color: rgba(29, 53, 85);
}

.numero{
    font-size: 80px;
    margin: 0;
    font-weight: bolder;
}
.stat_titre{
    font-size: 25px;
    text-transform: uppercase;
}

.trust{
    margin-top: 5%;
}
.trust_txt{
    display: flex;
    justify-content: center;
}

.trust_txt p{
    width: max-content;
    font-family: 'neue';
    font-size: 40px;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;
    margin-bottom: 5%;
}

.milieu{
    display: flex;
    align-self: center;
}

@media screen and (max-width: 431px){
    .expli{
        column-gap: 20px;
        flex-direction: column;
        align-items: center;
        padding: 5%;
    }

    .expli p {
        font-size: 17px;
        width: 100%;
        text-align: center;
    }

    .img_birm{
        width: 300px;
    }

    .stats{
        column-gap: 20px;
    }
    
    .stat1{
        width: 110px;
        height: 110px;
    }

    .numero{
        font-size: 40px;
    }
    .stat_titre{
        margin-top: 2px;
        font-size: 15px;
        text-align: center;
    }

    .trust_txt p{
        font-size: 30px;
        margin-bottom: 10%;
    }

    .slider img{
        width: 150px;
        height: auto;
    }
}