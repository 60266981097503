.services{
    background-color: rgb(255, 255, 255);
    margin-bottom: 5%;
}

h1{
    font-family: 'harper';
    font-size: 60px;
    display: flex;
    justify-content: center;
    color: rgb(87, 132, 190);
    padding-top: 3%;
}

.service{
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 100px;
    padding-bottom: 5%;
}

.service img{
    object-fit: cover;
    width: 300px;
    height: 400px;
    border-radius: 25px;
}

.service p{
    display: flex;
    justify-content: center;
    font-family: 'neue';
    font-size: 25px;
}
.image{
    overflow: hidden;
}

.service_txt{
    display: flex;
    justify-content: center;
    font-size: 25px;    
}

.service_txt p{
    width: 900px;
    text-align: center;
    font-family: 'neue';
    font-size: 25px;
}


@media screen and (max-width: 431px){

    h1{
        margin-bottom: 0;
    }
    .service {
        padding: 5%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
        row-gap: 20px;
    }

    .service img {
        width: 100%; /* Make images responsive */
        height: 200px;
    }

    .service p {
        margin-bottom: 10px; /* Remove margin between images */
        text-align: center;
    }

    .service_txt p{
        padding: 5%;
    }
}