.contact{
    margin-bottom: 8%;
}
.contacts{
    display: flex;
    justify-content: center;
    column-gap: 100px;
    margin-top: 5%;
}


.mail{
    text-decoration: none;
    color: black;
}

.mail:active{
    color:black;
}
.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .form_area {
    font-family: 'neue';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(238, 245, 255);
    height: auto;
    width:fit-content;
    border: 2px solid #264143;
    border-radius: 20px;
    box-shadow: 3px 4px 0px 1px rgb(87, 132, 190);
    padding-left: 2%;
    padding-right: 2%;
  }
  
  .title {
    font-family: 'harper';
    color: #264143;
    font-weight: 900;
    font-size: 1.5em;
    margin-top: 20px;
  }
  
  .sub_title {
    font-weight: 600;
    margin: 5px 0;
  }
  
  .form_group {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 10px;
  }

  .form_group input{
    width: 400px;
  }

  
  .form_style {
    font-family: 'neue';
    outline: none;
    border: 2px solid #264143;
    box-shadow: 3px 4px 0px 1px rgb(87, 132, 190);
    padding: 12px 10px;
    border-radius: 4px;
    font-size: 15px;
  }
  
  .form_style:focus, .btn:focus {
    transform: translateY(4px);
    box-shadow: 1px 2px 0px 0px rgb(87, 132, 190);
  }

  #message{
    height: 150px;
    width: 600px;
    resize: none;
  }
  
  .btn {
    padding: 15px;
    margin: 25px 0px;
    width: 290px;
    font-size: 15px;
    background: rgb(177, 211, 255);
    border-radius: 10px;
    font-weight: 800;
    box-shadow: 3px 3px 0px 0px rgb(87, 132, 190);
  }
  
  .btn:hover {
    opacity: .9;
    cursor: pointer;
  }
  
  @media screen and (max-width: 431px){

    .contacts{
      column-gap: 15px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 10%;
    }

    button {
      font-family: 'neue';
      font-size: 13px;
    }
    
    .button_top {
      padding: 10px 10px;
  }

  .container{
    margin-bottom: 20%;
    padding-left: 5%;
    padding-right: 5%;
  }

  .form_group input{
    width: 175px;
    height: 10px;
  }

  #message{
    height: 100px;
    width: 275px;
    resize: none;
  }

  .btn {
    padding: 15px;
    margin: 25px 0px;
    width: 200px;
    font-size: 15px;
  }
}