.prix{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    margin-bottom: 5%;
}

.card {
    flex-direction: column;
    box-sizing: border-box;
    width: 600px;
    height: auto;
    background: rgb(238, 245, 255);
    border: 4px solid rgb(87, 132, 190);
    box-shadow: 12px 17px 51px rgba(0, 0, 0, 0.22);
    backdrop-filter: blur(6px);
    border-radius: 17px;
    text-align: center;
    transition: all 0.5s;
    display: flex;
    align-items: center;
    user-select: none;
    color: black;
  }
  
  .card:hover {
    border: 5px solid rgb(29, 53, 85);
    transform: scale(1.05);
  }
  
  .card:active {
    transform: scale(0.95) rotateZ(1.7deg);
  }

.prix_titre{
    margin-top: 5%;
    font-size: 30px;
    font-family: 'neue';
    border-bottom: 1px solid black;
}

.prix_prix{
    font-family: 'neue';
    font-size: 60px;
    font-weight: bolder;
}

.add{
    font-family: 'neue';
    padding-bottom: 1%;
    font-size: 17px;
}

@media screen and (max-width: 431px){
  .card{
    width: 300px;
    height: auto;
  }

  .prix_titre{
    width: 100%;
    font-size: 30px;
    border: 0;
  }

  .prix_prix{
    font-size: 45px;
  }

  .add{
    font-size: 18px;
  }
}