

.home{
    padding-top: 9%;
    padding-bottom: 6%;
    background-color: rgb(222, 236, 255);
    overflow: hidden;
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
}

.name{
    font-family: 'harper';
    font-size: 80px;
    display: flex;
    justify-content: center;
    margin-bottom: 1%;
    margin-top: 1%;
}

.slogan{
    margin-bottom: 2%;
    font-family: 'neue';
    font-size: 25px;
    display: flex;
    justify-content: center;
    color: rgb(87, 132, 190);


}
.slogan2{
    font-family: 'neue';
    font-size: 28px;
    display: flex;
    justify-content: center;
}

.bulle{
    position: absolute;
}

#bulle1{
    height: 150px;
    left: 150px;
    top:-40px
}
#bulle2{
    height: 120px;
    right: 200px;
    top:-80px
}
#bulle3{
    height: 200px;
    right: 80px;
    top:280px
}
#bulle4{
    height: 240px;
    left: -90px;
    top:290px
}
#bulle5{
    height: 100px;
    left: 290px;
    top:370px;
}
#bulle6{
    height: 170px;
    right: -100px;
    top:20px
}
#bulle7{
    height: 100px;
    left: -50px;
    top:-20px;
}

.tel{
    margin-top: 6%;
    display: flex;
    justify-content: center;
}

button {

    --button_radius: 0.75em;
    --button_color: #ffffff;
    --button_outline_color: black;
    font-size: 17px;
    font-weight: bold;
    border: none;
    border-radius: var(--button_radius);
    background: var(--button_outline_color);
  }
  
  .button_top {
    display: block;
    box-sizing: border-box;
    border: 2px solid var(--button_outline_color);
    border-radius: var(--button_radius);
    padding: 0.75em 1.5em;
    background: var(--button_color);
    color: var(--button_outline_color);
    transform: translateY(-0.2em);
    transition: transform 0.1s ease;
    color: black;
  }
  
  button:hover .button_top {
    /* Pull the button upwards when hovered */
    transform: translateY(-0.33em);
    cursor: pointer;
  }
  
  button:active .button_top {
    /* Push the button downwards when pressed */
    transform: translateY(0);
  }


  @media screen and (max-width: 431px){


    .bulle{
        display: none;
    }
    .name{
        margin-top: 15%;
        font-size: 55px;
    }

    .slogan2{
        text-align: center;
        margin-bottom: 15%;
        margin-top: 10%;
    }
  }